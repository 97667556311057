// Import Axis Base SCSS
// @import "~axis-components/styles/axis";
@import "node_modules/axis-components/styles/core/fonts/source-jp";
@import "node_modules/axis-components/styles/core/fonts/source";
// $font-family-base:'aig_meiryo';
@import "node_modules/axis-components/styles/theming/external/main";
body {
  background: #f0f2f5;
}


// @media (min-width: 768px) {
//   .axis-sticky-body .axis-sticky-card {
//     top: 0;
//     position: fixed;
//     left: 0;
//     width: 100%;
//     z-index: 10;
//   }
// }


// .axis-btn-block.custom-btn {
//   word-wrap: break-word;
//   white-space: normal;
//   min-height: 90px;
//   margin-bottom: 16px;
//   flex: 1;
// }


// .login-box {
//   .axis-input-clear {
//     align-self: flex-start;

//     .material-icons {
//       color: #fff;
//     }
//   }
// }

// .axis-sticky-body .dashboard-container {
//   margin-top: 142px;
// }

// .axis-btn[data-kind="primary"] {
//   color: rgba(0, 0, 0, 0.75);
//   background: #fdb813;
//   border-color: transparent;
// }

// .axis-btn[data-kind="primary"]:hover {
//   color: rgba(0, 0, 0, 0.75);
//   background: #fba137;
//   border-color: transparent;
// }

// .axis-btn[data-kind="secondary"] .test-class {
//   color: #fff;
//   background: #0057b8;
//   border-color: transparent;
// }

// .axis-btn[data-kind="secondary"]:hover .test-class {
//   color: #fff;
//   background: linear-gradient(45deg, #0057b8 0, #017ad6 100%);
//   border-color: transparent;
// }


// .axis-list-unstyled>li //for DE26247

//   {
//   margin-bottom: 8px;
// }

label-menu .material-icons {
  font-size: 1.3rem !important;
}
.axis-tooltip-content{
// max-width: none !important; // WHY THIS IS ADDED?
}
.customCheck axis-checkbox.axis-checkbox{
  display: inline-block !important;
}
.customCheck axis-checkbox.axis-checkbox {
  margin-left: 20px !important;
}
.customCheck axis-checkbox.axis-checkbox:first-child{
margin-left: 0 !important;
}
.customCheck axis-checkbox.axis-checkbox:last-child{
  margin-left: 20px !important;
    margin-right: 20px !important;
}
.axis-datepicker-wrapper, .axis-timepicker-wrapper{
  width: 100%;
}

.axis-footer-list.sgList li {
  margin-bottom: 8px;
}
.toggleSection{
  margin-top: -85px;
}


.axis-grid-virtual-container {
  height: 350px !important;
}
.bulletCustom::before { /* add the new bullet point */
  display: inline-block;
  content: '';
  border-radius: 0.375rem;
  height: 0.3rem;
  width: 0.3rem;
  margin-right: 1rem;
  background-color: #000;
}
.errorCustomMsg{
  color: #e81944 !important;
}
.customDatePicker .axis-datepicker-wrapper{
  width: 100% !important;
}
.customDatePicker .axis-multi-btn {
  align-items: center !important;
}
 .claimTop {
    margin-top: -120px;
    padding: 2rem 1rem;
}
.button_align{
  margin-top: -80px !important;
}
@media (max-width: 768px) {
  .customCheck axis-checkbox.axis-checkbox {
    margin-left: 0px !important;
}
.customCheck axis-checkbox.axis-checkbox {
  display: block !important;
}
.customCheck axis-checkbox.axis-checkbox:last-child {
  margin-left: 0px !important;
}
.privacy{
  margin-top: 9px !important;
}
.toggleSection {
  margin-top: 20px;
}
.toggleImg{
  height: auto;
}
.axis-custom-heading-space h2{
  margin-bottom:0px;
}

}
.datepicker-btn{
  + .datepicker-btn{
    margin-left: $spacer * 1.5 !important;
  }
}
.pac-container {
  z-index:1052 ;
}
.axis-dropdown-menu .axis-dropdown-list-item > a > img, 
.axis-dropdown-menu .axis-dropdown-item > img, .axis-dropdown-menu .axis-dropdown-img,
.axis-dropdown .axis-dropdown-toggle > img {
  border-radius: 50% !important;
}
.privacy {
  cursor: pointer;
}
.buttonPostion .axis-input-group-btn {
  position: absolute !important;
  right: 0 !important;
  top: 32px !important;
}

.axis-custom-browser-icons img{
  height:48px;
}
.axis-custom-text-normal span{
  font-weight: 400!important;
}
.axis-custom-accordion-space .axis-accordion-group-wrapper{
  margin-bottom: 1rem;
}